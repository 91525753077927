/* eslint-disable react/jsx-no-useless-fragment */
import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import useAuthentication from 'hooks/useAuthentication';
import { useLanguage } from 'hooks/useLanguage';
import i18n from 'i18n';
import { useEffect } from 'react';
import { ELocale } from 'types';

type Props = {
    children: React.ReactNode;
};

function PublicRoute({ children }: Props): React.JSX.Element {
    const { inProgress, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const { loginRedirectAnonymous } = useAuthentication();
    const { getValidBULanguage } = useLanguage();

    useEffect(() => {
        i18n.changeLanguage(getValidBULanguage(i18n.language as ELocale));
        if (!isAuthenticated && inProgress === InteractionStatus.None && accounts.length === 0) {
            loginRedirectAnonymous();
        }
    }, [isAuthenticated, inProgress, loginRedirectAnonymous, accounts, getValidBULanguage]);

    if (isAuthenticated && inProgress === InteractionStatus.None) {
        return <>{children}</>;
    }

    return <></>;
}

export default PublicRoute;
