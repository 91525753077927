import ApiError from 'classes/ApiError';
import React, { ReactNode, use, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
    children: ReactNode;
};

export type ErrorContextType = {
    errors: ApiError[];
    setError: (error: ApiError) => void;
    setErrors: (errors: ApiError[]) => void;
};

const ErrorContext = React.createContext<ErrorContextType>({
    errors: [],
    setError: () => undefined,
    setErrors: () => undefined,
});

export function ErrorProvider(props: Props): React.JSX.Element {
    const [errorsState, setErrorsState] = useState<ApiError[]>([]);
    const { pathname } = useLocation();
    const [previousPathname, setPreviousPathname] = useState(pathname);

    useEffect(() => {
        if (pathname !== previousPathname) {
            setPreviousPathname(pathname);
            setErrorsState([]);
        }
    }, [pathname, previousPathname, setPreviousPathname, setErrorsState]);

    const setErrors = useCallback(
        (errors?: ApiError[]) => {
            if (errors && errors.length > 0) {
                setErrorsState((errs) => [...errs, ...errors]);
            }
        },
        [setErrorsState],
    );

    const setError = useCallback(
        (error?: ApiError) => {
            if (error) {
                setErrorsState((errs: ApiError[]) => [...errs, error]);
            }
        },
        [setErrorsState],
    );

    const context = useMemo(
        () => ({
            errors: errorsState,
            setError,
            setErrors,
        }),
        [errorsState, setError, setErrors],
    );
    return <ErrorContext value={context} {...props} />;
}

export const useErrors = (): ErrorContextType => use(ErrorContext);
